import { SIGN_IN, SIGN_OUT, CHECK_AUTH } from '../actions/actionType';
import Cookies from 'js-cookie';

const auth = JSON.parse(Cookies.get('auth') || '{}');

const initialState = {
  token: auth.token,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SIGN_IN: {
      return { ...state, token: payload };
    }

    case SIGN_OUT: {
      return { ...state, token: '' };
    }

    case CHECK_AUTH: {
      return { ...state, token: payload };
    }

    default: {
      return state;
    }
  }
};
