import React from 'react';
import history from '../../history';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { signIn } from '../../actions/auth';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { setSnackbar } from '../../actions/snackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '360px',
    padding: '20px 20px 60px',
    boxShadow: '0 0 6px rgb(216,216,216)',

    '& .MuiTextField-root': {
      display: 'block',
      width: '300px',
      margin: '20px auto',
    },
    '& .MuiFormLabel-root': {
      fontSize: '16px',
    },
    '& button': {
      display: 'block',
      margin: '40px auto 0',
    },
    '& h1': {
      textAlign: 'center',
    },
  },
}));

const validationSchema = yup.object({
  username: yup.string().required('username is required'),
  password: yup.string().required('Password is required'),
});

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const formikData = JSON.parse(JSON.stringify(values));

      try {
        await dispatch(signIn({ ...formikData, password: formikData.password }));
        formik.handleReset();
        history.push('/');

        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'success',
            snackbarMessage: '已登入',
          })
        );
      } catch (error) {
        dispatch(
          setSnackbar({
            isSnackbarOpen: true,
            snackbarType: 'error',
            snackbarMessage: `帳號密碼錯誤`,
          })
        );
      }
    },
  });

  return (
    <form className={classes.root} autoComplete="off" onSubmit={formik.handleSubmit}>
      <h1>Login</h1>
      <TextField
        fullWidth
        id="username"
        name="username"
        label="username"
        value={formik.values.username}
        error={formik.touched.username && Boolean(formik.errors.username)}
        onChange={formik.handleChange}
        helperText={formik.touched.username && formik.errors.username}
      />
      <TextField
        fullWidth
        id="password"
        type="password"
        name="password"
        label="Password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <Button color="primary" variant="contained" type="submit">
        Login
      </Button>
    </form>
  );
};

export default LoginForm;
